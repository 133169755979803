import React from "react";
import { useState } from "react";
import { Social } from "../home/Social";
import { Link } from "react-scroll";

import "./header.css";

export const Header = () => {
  const [Toggle, showMenu] = useState(false);

  return (
    <>
      <header className="header">
        <nav className="nav container">
          <a href="index.html" className="nav_logo">
            <Social />
          </a>
          <div className={Toggle ? "nav_menu show_menu" : "nav_menu"}>
            <ul className="nav_list grid">
              <li className="nav_item">
                <Link to="home" smooth duration={1000}>
                  <a href="#home" className="nav_link active_link">
                    <i className="uil uil-estate nav_icon"></i> Home
                  </a>
                </Link>
              </li>
              <li className="nav_item">
                <a href="#about" className="nav_link">
                  <i className="uil uil-user-square nav_icon"></i> About
                </a>
              </li>
              <li className="nav_item">
                <Link to="skills" smooth duration={1000}>
                  <a href="#skills" className="nav_link">
                    <i className="uil uil-bolt nav_icon"></i> Skills
                  </a>
                </Link>
              </li>
              {/*TODO fix Services link*/}
              <li className="nav_item">
                <a href="#tech" className="nav_link">
                  <i className="uil uil-book nav_icon"></i> Blog
                </a>
              </li>
              <li className="nav_item">
                <a href="#projects" className="nav_link">
                  <i className="uil uil-folder nav_icon"></i> Projects
                </a>
              </li>
              <li className="nav_item">
                <a href="mailto: hi@kecheng.dev" className="nav_link">
                  <i className="uil uil-message nav_icon"></i> Contact
                </a>
              </li>
            </ul>
            <i
              class="uil uil-times-circle nav_close"
              onClick={() => showMenu(!Toggle)}
            ></i>
          </div>
          <div className="nav_toggle" onClick={() => showMenu(!Toggle)}>
            <i class="uil uil-list-ul"></i>
          </div>
        </nav>
      </header>
    </>
  );
};
