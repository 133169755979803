import React from "react";
import Typed from "typed.js";

export const Typing = () => {
  // Create reference to store the DOM element containing the animation
  const el = React.useRef(null);

  React.useEffect(() => {
    const typed = new Typed(el.current, {
      strings: [
        "<strong>Back End Developer</strong>",
        "<strong>Front End Developer</strong>",
        "<strong>Full Stack Developer</strong>",
      ],
      typeSpeed: 30,
      backSpeed: 30,
      smartBackspace: true, // this is a default
      loop: true,
    });

    return () => {
      // Destroy Typed instance during cleanup to stop animation
      typed.destroy();
    };
  }, []);

  return (
    <>
      I'm a <span ref={el} />
    </>
  );
};
