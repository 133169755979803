import React from "react";
import "./App.css";
import { Header } from "./components/header/Header";
import { Home } from "./components/home/Home";
import { Fade } from "react-awesome-reveal";
import { Skills } from "./components/skills/Skills";

function App() {
  return (
    <>
      <Fade cascade damping={1} triggerOnce>
        <main className="main">
          <Header />
          <Home />
          <Skills />
        </main>
      </Fade>
    </>
  );
}

export default App;
