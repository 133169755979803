import React from "react";

export const Social = () => {
  return (
    <div className="home_social">
      <a href="https://github.com" className="home_social-icon" target="_blank">
        <i class="uil uil-github-alt"></i>
      </a>

      <a
        href="https://linkedin.com"
        className="home_social-icon"
        target="_blank"
      >
        <i class="uil uil-linkedin-alt"></i>
      </a>
    </div>
  );
};
